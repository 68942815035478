document.addEventListener("turbolinks:load", function() {
    $('.dataTables_length').remove();
    $('.dataTables_paginate').remove();

    var table = $('.datatable').DataTable({
      dom: "<f<t>lip>",
      info: false,
      language: {
        url: '/assets/dataTables.portuguese.json'
      },
      stateSave: true,
      columnDefs: [
        {
          targets: 'no-sort',
          orderable: false,
        }
      ],
      "initComplete": function( settings, json ) {
        $(this).fadeIn("fast");
      }
    });
    // fix bug on search and reload
    if(table && table['context'] && table['context'][0] && table['context'][0]['oPreviousSearch']['sSearch']){
      $('#search').val(table['context'][0]['oPreviousSearch']['sSearch']);
    }
    $('#search').keyup(function() {
      table.search($(this).val()).draw();
    });

    $('.table-toggler').on('click', function() {
      var lineId = $(this).attr('line-id');
      var next = $(this).parent().parent().next('tr');

      if (next && next.attr('id')) {
        $('tr#tr_' + lineId + ' div').removeClass('table-open');
        setTimeout(() => {
          next.remove();
        }, 300); // CSS animation time
        $(this).text('Mostrar');
      } else {
        $(this).parent().parent().after('<tr id="tr_' + lineId + '">' + $('tr#' + lineId).html() + '</tr>');
        setTimeout(() => {
          $('tr#tr_' + lineId + ' div').addClass('table-open');
        }, 10);
        $(this).text('Ocultar');
      }
    });

    function removeAccents ( data ) {
      if ( data.normalize ) {
        // Use I18n API if avaiable to split characters and accents, then remove
        // the accents wholesale. Note that we use the original data as well as
        // the new to allow for searching of either form.
        return data +' '+ data
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '');
      }

      return data;
    }

    var searchType = jQuery.fn.DataTable.ext.type.search;
    searchType.string = function ( data ) {
      return ! data ?
        '' :
        typeof data === 'string' ?
          removeAccents( data ) :
          data;
    };

    searchType.html = function ( data ) {
      return ! data ?
        '' :
        typeof data === 'string' ?
          removeAccents( data.replace( /<.*?>/g, '' ) ) :
          data;
    };
});