// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";

Rails.start();
Turbolinks.start();
ActiveStorage.start();

import "bootstrap/dist/js/bootstrap";
window.bootstrap = require("bootstrap/dist/js/bootstrap");
import "@fortawesome/fontawesome-free";

var jQuery = require("jquery");
// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

import "controllers";

window.dispatchMapsEvent = function (...args) {
  const event = document.createEvent("Events");
  event.initEvent("google-maps-callback", true, true);
  event.args = args;
  window.dispatchEvent(event);
};

global.toastr = require("toastr");

require("packs/jquery.cookie");
require("packs/datatable");
require("packs/fixedColumns");
require("packs/backoffice_datatable");
import "chartkick/chart.js"
import "bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js"

document.addEventListener("turbolinks:load", function() {
  $( "select" ).change(function() {
    $(this).removeClass("text-muted");
    if ($(this).val() == ''){
      $(this).addClass("text-muted");
    }
  });

  $("select").trigger("change");
});